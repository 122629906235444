import axios from 'axios';

const API_BASE_URL = 'https://parakh-apis.azurewebsites.net/api';

export const fetchVideos = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/videos`);
        debugger
        return response.data;
    } catch (error) {
        console.error('Error fetching videos:', error);
        throw error;
    }
};

export const adminLogin = async (email, password) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/auth/admin`, { email, password }, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
            },
        });

        if (response.status === 200) {
            const { accessToken } = response.data;

            // Store the accessToken in localStorage
            localStorage.setItem('accessToken', accessToken);

            // Optionally, you can store it in session storage if you prefer:
            // sessionStorage.setItem('accessToken', accessToken);

            console.log('Login successful, accessToken stored:', accessToken);
        } else {
            console.error(`${response.data}`);
        }

        return response.data;
    } catch (error) {
        console.error('Error:', error);
        throw error;
    }
}

export const checkAuth = async () => {
    try {
        // Retrieve the accessToken from localStorage
        const accessToken = localStorage.getItem('accessToken');

        // If the token doesn't exist, handle accordingly (e.g., throw an error, redirect to login, etc.)
        if (!accessToken) {
            throw new Error('No access token found. Please log in.');
        }

        // Make the authenticated request
        const response = await axios.get(`${API_BASE_URL}/auth/check`, {
            headers: {
                'Authorization': `Bearer ${accessToken}`,
                'Content-Type': 'application/json',
            },
        });

        // Check the status code
        if (response.status === 200) {
            // If status is 200, return the response data
            return response.data;
        }
    } catch (error) {
        // If the error has a response (it's an HTTP error)
        if (error.response) {
            const status = error.response.status;

            if (status === 401 || status === 403) {
                // Redirect to root if unauthorized or forbidden
                window.location.href = '/';
            } else {
                console.error(`Error ${status}:`, error.response.data);
            }
        } else {
            // Handle other errors (e.g., network errors)
            console.error('Error:', error.message);
        }

        throw error; // Re-throw the error to allow further handling if needed
    }
}


export const fetchVideoDetails = async (id) => {
    try {
        const response = await axios.get(`${API_BASE_URL}/videoById?videoId=${id}`);
        return response.data;
    } catch (error) {
        console.error('Error fetching video details:', error);
        throw error;
    }
};

export const addVideo = async (video) => {
    try {
        const response = await axios.post(`${API_BASE_URL}/videos`, video, {
            headers: {
                'Content-Type': 'application/json',
                'accept': 'application/json',
            },
        });
        return response.data;
    } catch (error) {
        console.error('Error adding video:', error);
        throw error;
    }
};

export const deleteVideoById = async (id) => {
    try {
        const response = await axios.delete(`${API_BASE_URL}/videoById`, { data: { id } });
        debugger
        return response.data;
    } catch (error) {
        console.error('Error deleting video:', error);
        throw error;
    }
};

export const exportVideos = async () => {
    try {
        const response = await axios.get(`${API_BASE_URL}/videos/export`, {
            responseType: 'blob',
        });

        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'video-user-data.xlsx');
        document.body.appendChild(link);
        link.click();
        link.remove();
    } catch (error) {
        console.error('Error exporting videos:', error);
        throw error;
    }
};
